import Splide from '@splidejs/splide'
import { dialog } from './_dialog'

export const imageGallery = (): void => {
  const sliderElement = document.querySelector('.js-image-gallery-splide') as HTMLElement

  if (!sliderElement) {
    return
  }

  const slider = new Splide(sliderElement, {
    type: 'fade',
    rewind: true,
    pagination: false,
    arrows: true,
  })

  slider.mount()

  const imageGallery = document.querySelector('.js-image-gallery') as HTMLElement

  if (!imageGallery) {
    return
  }

  const imageGalleryChildren = [...imageGallery.children]

  const callback = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowLeft':
        slider.go('-1')
        break
      case 'ArrowRight':
        slider.go('+1')
        break
      default:
        return
    }
  }

  dialog({
    selector: '.js-image-gallery-dialog',
    onShow: (_, event) => {
      document.addEventListener('keydown', callback)

      const clickedEl = event?.target as Element
      const parentLi = clickedEl.closest('.js-image-gallery-item') as HTMLElement
      slider.go(imageGalleryChildren.indexOf(parentLi))
    },
    onHide() {
      document.removeEventListener('keydown', callback)
    },
  })
}
