import '../styles/main.css'

import 'lazysizes'
import 'what-input'

import { imageGallery } from './_image-gallery'
import { mainImage } from './_main-image'
import { navToggle } from './_nav-toggle'
import { newsImage } from './_news-image'

imageGallery()
mainImage()
navToggle()
newsImage()
