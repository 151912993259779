export const navToggle = (): void => {
  const navButton = document.querySelector('.js-nav-toggle') as HTMLElement
  const navMenu = document.querySelector('.js-nav-dropdown') as HTMLElement

  if (!navButton && !navMenu) {
    return
  }

  const classNavHidden = 'main-header__nav-dropdown_hidden'

  navButton.addEventListener('click', function () {
    const expanded = this.getAttribute('aria-expanded') === 'true' || false
    this.setAttribute('aria-expanded', `${!expanded}`)
    navMenu.classList.contains(classNavHidden)
      ? navMenu.classList.remove(classNavHidden)
      : navMenu.classList.add(classNavHidden)
  })
}
