import A11yDialog from 'a11y-dialog'

type Dialog = {
  selector: string
  onShow?: (element?: Element, event?: Event) => void
  onHide?: (element?: Element, event?: Event) => void
}

export const dialog = ({ selector, onShow, onHide }: Dialog): void => {
  const dialogContainers = document.querySelectorAll(selector)

  if (!dialogContainers.length) {
    return
  }

  dialogContainers.forEach((dialogContainer) => {
    const dialog = new A11yDialog(dialogContainer)

    dialog
      .on('show', (element, event) => {
        document.documentElement.style.overflow = 'hidden'
        document.body.style.overflow = 'hidden'

        onShow && onShow(element, event)
      })
      .on('hide', (element, event) => {
        document.documentElement.style.overflow = ''
        document.body.style.overflow = ''

        onHide && onHide(element, event)
      })
  })
}
